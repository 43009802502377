<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { RecentWinners } from "@/types";
const { t } = useT();

defineProps<{
	games: RecentWinners;
}>();

const sliderRef = ref();
const isDisabledNextMove = ref(false);
const sliderOptions: KeenSliderOptions = {
	slides: { perView: "auto", spacing: 8 },
	animationEnded(slider) {
		isDisabledNextMove.value = (slider.track.details.slides.at(-1)?.portion ?? 0) >= 0.9;
	}
};

const updateSlider = () => {
	nextTick(() => {
		sliderRef.value?.slider?.update();
	});
};
</script>

<template>
	<div class="wrapper">
		<AText variant="tanzay" :modifiers="['bold']">{{ t("Top Winners and Jackpots") }}</AText>

		<client-only>
			<ASlider ref="sliderRef" class="list" :options="sliderOptions">
				<TransitionGroup name="list" @after-enter="updateSlider">
					<div v-for="item in games" :key="`card-${item.type}-${item.id}-${item.login}`" class="keen-slider__slide">
						<div class="card">
							<MRecentGameCard class="card-item" :item="item" />
						</div>
					</div>
				</TransitionGroup>
			</ASlider>
		</client-only>
	</div>
</template>

<style scoped lang="scss">
.wrapper {
	max-width: calc(100% - 32px);
	width: 1312px;
	overflow: hidden;
	margin: 64px auto 0;
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.card {
	width: 240px;
}
</style>
